main {
  display: grid;
  grid-template-rows: 0fr 1fr 0fr;
  gap: 20px;
}

.projects {
  height: fit-content;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.container {
  position: relative;
}

h1 {
  font-family: "Open Sauce One Regular";
}

@media (max-width: 768px) {
  .projects {
    grid-template-columns: 1fr;
  }
}
