.cardContainer {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px);
  border-radius: 2em;
  overflow: hidden;
  width: 100%;

  h1 {
    font-family: "Open Sauce One SemiBold";
    font-size: clamp(1.8em, 2.8vw, 7vw);
    margin: 0;
    z-index: 1;
  }
  p {
    font-size: clamp(10px, 0.8rem, 3vw);
    color: rgb(65, 60, 60);
    z-index: 1;
    margin: auto 0;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    border-radius: 2em;
  }
}

.cardContent {
  position: relative;
  z-index: 2;
  height: 100%;
  padding: 2em 8em 2em 2em;
  backdrop-filter: blur(20px);
  background-color: rgba(252, 249, 242, 0.35);
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media (max-width: 768px) {
  .cardContent {
    padding: 2em;
    height: 100%;
    gap: 20px;
  }

  .cardContainer {
    height: 100%;
    h1 {
      font-size: clamp(10px, 1.8rem, 6vw);
    }
    p {
      font-size: clamp(10px, 0.9rem, 3vw);
    }
  }
}
