.navIcon {
  display: none;
  width: 60px;
  height: 45px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 50%;
    background: rgb(36, 36, 36);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }

  span:nth-child(odd) {
    left: 0;
    border-radius: 9px 0 0 9px;
  }

  span:nth-child(1),
  span:nth-child(2) {
    top: 0px;
  }
  span:nth-child(3),
  span:nth-child(4) {
    top: 18px;
  }
  span:nth-child(5),
  span:nth-child(6) {
    top: 36px;
  }

  &.open {
    span:nth-child(1),
    span:nth-child(6) {
      transform: rotate(45deg);
    }

    span:nth-child(2),
    span:nth-child(5) {
      transform: rotate(-45deg);
    }

    span:nth-child(1) {
      left: 5px;
      top: 7px;
    }

    span:nth-child(2) {
      left: calc(50% - 5px);
      top: 7px;
    }

    span:nth-child(3),
    span:nth-child(4) {
      opacity: 0;
      left: 100%;
    }

    span:nth-child(5) {
      left: 5px;
      top: 29px;
    }

    span:nth-child(6) {
      left: calc(50% - 5px);
      top: 29px;
    }
  }
}

@media (max-width: 768px) {
  .navIcon {
    display: block;
    transform: scale(0.4);
    left: 1rem;
  }
}
