.projectDetails {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;

  div {
    background-color: white;
    padding: 2em;
    width: clamp(18em, 600px, 70vw);
    margin: 0 2em;
    border-radius: 2em;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: -1;
    position: relative;
    backdrop-filter: blur(10px);

    h1 {
      margin: unset;
    }
    P {
      margin: unset;
      color: rgb(55, 55, 55);
      font-size: smaller;
    }

    button {
      width: clamp(10em, 20em, 25em);
      margin: 0 auto;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -2;
      border-radius: 2em;
    }
  }

  span {
    position: fixed;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.786);
    z-index: -1;
  }
}

.technologiesIcon {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 20px;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2em;
    width: 2em;
    padding: 0.5em;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.projectLink {
  position: absolute;
  right: 1em;
  top: 1em;
  transform: scale(0.7);
  cursor: pointer;
  color: black !important;

  &:hover {
    transform: scale(0.9);
  }
}

.projectRepoLink {
  right: 4em;
  color: white !important;
  background-color: rgb(120, 9, 225) !important;
}

.viewProjectLink {
  width: fit-content;
  margin: 0 auto;
  z-index: 1;
  margin-bottom: -1em;
}
