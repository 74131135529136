.header {
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: top 0.3s;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
  font-size: 0.8em;
  z-index: 10000;

  ul {
    display: flex;
    gap: 3em;
    display: flex;
    align-items: center;
    li {
      list-style: none;

      a {
        transition: color 0.5s, transform 0.5s;
        display: inline-block;
        color: rgb(41, 40, 40);
      }
    }

    li:hover a {
      color: rgb(0, 0, 0);
      transform: scale(1.1);
    }
  }
}

.navIcon img {
  height: 40px;
  width: 40px;
}

.navContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 990px) {
  .navContainer {
    max-width: clamp(65rem, 1100px, 70vw);
    padding-left: calc(var(--bs-gutter-x) * 1);
    padding-right: calc(var(--bs-gutter-x) * 1);
    margin: 0 auto;
    box-sizing: border-box;
  }
}

@media (min-width: 768px) {
  .hide {
    top: -100%;
    transition: 1s;
  }
}

@media (max-width: 768px) {
  .header {
    height: 5rem;
  }

  .menuHidden,
  .menuVisible {
    position: absolute;
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    background-color: white;
    top: 0;
    transition: left 0.5s;
    overflow: hidden;

    ul {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      gap: 4rem;
      padding: 0;
    }
  }

  .menuHidden {
    left: 100%;
  }

  .menuVisible {
    left: 0;
  }
}
