.projectContainer {
  aspect-ratio: 1;
  width: 100%;
  padding: 2em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  color: white;
  overflow: hidden;
  border-radius: 2em;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  cursor: pointer;
  div {
    padding: 0 3rem 0 0;
  }
  h4 {
    font-size: clamp(10px, 1rem, 6vw);
    margin: unset;
    padding: unset;
  }
  p {
    font-size: clamp(10px, 0.7rem, 3vw);
    color: rgba(255, 255, 255, 0.751);
    line-height: 16px;
  }

  .projectLink {
    height: 0.5em;
    width: 0.5em;
    font-size: 200 !important;
    transform: scale(0.7);
    position: absolute;
    top: 1em;
    right: 1em;
    background-color: rgba(254, 254, 254, 0.853);

    a {
      transform: scale(0.5) !important;
    }
  }

  .projectLink:hover {
    transform: scale(1);
    color: black;
  }

  button {
    position: absolute;
    left: 20%;
    bottom: 1.5em;
    width: 60%;
    font-size: smaller !important;
  }
}

.projectImage:hover img {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .projectContainer {
    line-height: 10px;

    h4 {
      font-size: clamp(10px, 0.8rem, 6vw);
    }
    p {
      font-size: clamp(10px, 0.4rem, 6vw);
    }
  }
}

.projectContent {
  z-index: 2;
}

.projectImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  border-radius: 2em;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2em;
    z-index: -1;
    backface-visibility: hidden;
  }
}

.projectImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2em;
  z-index: -1;
  transition: transform 0.3s ease;
}

@media (max-width: 425px) {
  .projectContainer {
    p {
      display: none;
    }
  }
}
