.heroContainer {
  display: grid;
  grid-template-columns: 3fr 2fr;
  height: clamp(20em, 500px, fit-content);
  gap: 20px;
}

.profileImage {
  width: 100%;
  height: 100%;
  border-radius: 2em;
  overflow: hidden;
  background-image: url("https://iili.io/HDQrS24.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 768px) {
  .heroContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 3fr 4fr;
  }

  .profileImage {
    order: 1;
  }

  .introduction {
    order: 2;
  }
}
