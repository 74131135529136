@font-face {
  font-family: "Open Sauce One Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Open Sauce One Regular"),
    url("../assets/fonts/OpenSauceOne-Regular.woff") format("woff");
}

@font-face {
  font-family: "Open Sauce One Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Open Sauce One Bold"),
    url("../assets/fonts/OpenSauceOne-Bold.woff") format("woff");
}

@font-face {
  font-family: "Open Sauce One SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Open Sauce One SemiBold"),
    url("../assets/fonts/OpenSauceOne-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Open Sauce One Extra Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Open Sauce One Extra Bold"),
    url("../assets/fonts/OpenSauceOne-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Open Sauce One Light";
  font-style: normal;
  font-weight: normal;
  src: local("Open Sauce One Light"),
    url("../assets/fonts/OpenSauceOne-Light.woff") format("woff");
}

body {
  font-family: "Open Sauce One Regular";
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding: 0 1.5rem;
  margin: 4rem auto;
  width: 100%;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: initial;
}

@media (min-width: 990px) {
  body {
    max-width: clamp(64rem, 1100px, 75vw);
    padding-left: calc(var(--bs-gutter-x) * 1);
    padding-right: calc(var(--bs-gutter-x) * 1);
  }
}

@media (max-width: 768px) {
  body {
    margin-top: 5rem;
    margin-bottom: 1.5rem;
  }
}
